import { ORDER_INIT } from "config";
import { createContext, useContext, useState } from "react";

const OrderContext = createContext();

export function OrderProvider({ children }){
    const orderInit = ORDER_INIT();
    const [order, setOrder] = useState({...orderInit});

    return(
        <OrderContext.Provider value={{order, setOrder}} >
            {children}
        </OrderContext.Provider>
    );
}

export function useOrder(){
    const context = useContext(OrderContext);
    if(!context) throw new Error("OrderProvider 안에서 사용해야 합니다.");
    return context.order;
}

export function useSetOrder(){
    const context = useContext(OrderContext);
    if(!context) throw new Error("OrderProvider 안에서 사용해야 합니다.");
    return context.setOrder;
}

