import { onSilentRefresh, tokenUserApi } from 'api/commonApi';
import { useSetStore } from 'contexts/Store';
import useAsync from 'hooks/useAsync';
import { produce } from 'immer';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { cm_isEmpty, resApiCheck } from 'utils/common';

export default function NextUrl(){
    const params = useParams();
    const navigate = useNavigate();
    const setStore = useSetStore();

    const [asyncOnSilentRefresh] = useAsync(onSilentRefresh);
    const [asyncTokenUser] = useAsync(tokenUserApi);

    useEffect(()=> {
        localStorage.setItem("refresh_token", params._REFRESH_TOKEN);
        sessionStorage.setItem("TEMP_KEY", params.TEMP_KEY);
        sessionStorage.setItem("NEXT_URL", params.NEXT_URL);

        handleSignIn();
    },[])

    // 접속 사용자 기본 정보 받기
    const handleSignIn = async () => {
        //axio header token setting
        const response = await asyncOnSilentRefresh({});
        if(!resApiCheck(response)) return;

        //store US_MAP setting
        const resTokenUser = await asyncTokenUser({});
        if(!resApiCheck(resTokenUser)) return;

        if(!cm_isEmpty(resTokenUser.data)){
            let US_MAP = resTokenUser.data;
            setStore(produce((prevValue) => {
                prevValue.US_MAP = US_MAP;
            }))

            navigate("/" + decodeURIComponent(params.NEXT_URL));
        }
    }
}