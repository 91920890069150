import { orderChangeStatusApi, orderDeleteApi, orderListApi, orderReadApi, orderUpdateApi } from "api/orderApi";
import Pagination from "components/common/Pagination";
import PrintQueue from "components/common/PrintQueue";
import useTranslate from "components/language/useTranslate";
import OrderSplitModal from "components/modal/OrderSplitModal";
import RemakeModal from "components/modal/RemakeModal";
import RepairModal from "components/modal/RepairModal";
import OrderDetail from "components/order/OrderDetail";
import OrderList from "components/order/OrderList";
import OrderSearch from "components/order/OrderSearch";
import { _CO_TYPE_LAB, _INTERVAL_TIME, _LIMIT_PREV_CNT } from "config";
import { useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import useInterval from "hooks/useInterval";
import { produce } from "immer";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { cm_dateAddDay, cm_dateAddMonth, cm_dateMoment, cm_dateNow, cm_isEmpty, cm_swAlert, cm_swConfirm, resApiCheck, resResultAlert } from "utils/common";

export default function Order(){
    const store = useStore();
    const US_MAP = store.US_MAP;
    const location = useLocation();
    const t = useTranslate();

    const [listCount, setListCount] = useState(1);
    const limit = 10;

    // Api
    const [asyncOrderList] = useAsync(orderListApi);
    const [asyncOrderUpdate] = useAsync(orderUpdateApi);
    const [asyncOrderChangeStatus] = useAsync(orderChangeStatusApi);
    const [asyncOrderDelete] = useAsync(orderDeleteApi);

    // Filter Init
    const filterInit = useCallback(() => {
        //Locate - Filtering
        let initFtStatus = "";
        let initFtIssues = {};
        let initFtPlaStatus = "";
        let fromDate = cm_dateMoment(cm_dateAddMonth(cm_dateAddDay(new Date(), 1), -12), "YYYY-MM-DD");
        let period = "year";

        //Filter when you click status or Issue in Home
        if(sessionStorage.getItem('orderLocationKey') !== location.key && !cm_isEmpty(location.state) && !cm_isEmpty(location.state.key)){
            if(location.state.key === "status"){
                initFtStatus = `${location.state.status}`;
            }else if (location.state.key === "issue"){
                if(US_MAP.CO_TYPE !== _CO_TYPE_LAB){
                    initFtStatus = `'M','D','K','W','S','R','A'`; // HOME 진행중 이슈 클릭 => 완료 제외
                }else{
                    initFtStatus = `'W','K','S'`; // Lab HOME 진행중 이슈 클릭 => 완료 제외
                }
                let issue = location.state.status;
                initFtIssues["FT_ISSUE"] = "Y";

                if(issue === "FT_DC"){
                    initFtIssues[issue] = "Y";
                }else{
                    initFtIssues[issue] = 1;
                }
            }else if (location.state.key === "plaStatus"){
                initFtStatus = `'M','D','K','W','S','R','A'`; // HOME 진행중 이슈 클릭 => 완료 제외
                initFtPlaStatus = `${location.state.status}`
            }

            fromDate = "0000-01-01";
            period = "entire";

            sessionStorage.setItem("orderLocationKey", location.key);
        }

        //Default Init
        let initOrderListFilter = {
            FT_STATUS : initFtStatus,
            FT_PLASTATUS : initFtPlaStatus,
            FT_ISSUE : null,
            FT_PRINT : null,
            FT_RESCAN : null,
            FT_DC : null,
            FT_REMAKE : null,
            FT_FRDATE : fromDate,
            FT_TODATE : cm_dateNow("YYYY-MM-DD"),
            PAGE_ROWS: limit,
            PAGE_INDEX : 1,
            PERIOD : period,
            OD_COUNT : "Y"
        }

        //assign
        Object.assign(initOrderListFilter, initFtIssues);

        return initOrderListFilter;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location]);

    const [orderListFilter, setOrderListFilter] = useState(filterInit());
    // Data
    const [orderList, setOrderList] = useState([]);
    const [orderLineList, setOrderLineList] = useState([]);
    const [orderCnt, setOrderCnt] = useState({});
    const [orderMap, setOrderMap] = useState({});
    const [targetStatus, setTargetStatus] = useState("");

    // Modal
    const [isSplitOpen, setIsSplitOpen] = useState(false);
    const [isRemakeOpen, setIsRemakeOpen] = useState(false);
    const [isRepairOpen, setIsRepairOpen] = useState(false);
    const handleSplitModal = () => setIsSplitOpen((prevValue) => !prevValue);
    const handleRemakeModal = () => setIsRemakeOpen((prevValue) => !prevValue);
    const handleRepairModal = () => setIsRepairOpen((prevValue) => !prevValue);

    useEffect(() => {
        // Locate - OD_SEQ from Delivery
        if(sessionStorage.getItem('orderLocationKey') !== location.key &&!cm_isEmpty(location.state) && !cm_isEmpty(location.state.OD_SEQ)){
            handleOrderMap(location.state.OD_SEQ);
            sessionStorage.setItem("orderLocationKey", location.key);
        }

        handleOrderList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[orderListFilter]);

    useInterval(() => {
        handleOrderList();
    }, _INTERVAL_TIME);

    const handleOrderList = async () => {
        const response = await asyncOrderList(orderListFilter);
        if(!resApiCheck(response)) return;
        if(response.data.LIST_COUNT > 0){
            setOrderList(response.data.LIST);
            setOrderLineList(response.data.OL_LIST);
            setListCount(response.data.LIST_COUNT);
        }else{
            setOrderList([]);
            setOrderLineList([]);
            setListCount(0);
        }

        setOrderCnt(response.data.ODC_MAP);
    }

    // 주문 클릭시
    const handleOrderMap = async (OD_SEQ) => {
        const response = await orderReadApi({
            OD_SEQ : OD_SEQ
        });

        setOrderMap(response.data);
    }

    async function handleOrderStatus(OD_STATUS) {
        const {OD_MAP, FL_LIST} = orderMap;

        if(OD_STATUS === "E"){
            const confirm = await cm_swConfirm(t("confirm_order_delete"), "", "warning", t("common_yes"), t("common_no"));
            if(confirm) orderDelete();

        }else if(OD_STATUS === "A"){
            if (OD_MAP.OD_REMAKE >= _LIMIT_PREV_CNT) {
                cm_swAlert(t("order_limit_remake"), "warning");
                return;
            }

            setTargetStatus(OD_STATUS);
            handleRemakeModal();

        }else if(OD_STATUS === "P"){
            setTargetStatus("W");
            handleRepairModal();

        }else if(OD_STATUS === "R"){
            if (OD_MAP.OD_RESCAN >= _LIMIT_PREV_CNT) {
                cm_swAlert(t("order_limit_rescan"), "warning");
                return;
            }

            const confirm = await cm_swConfirm(t("confirm_order_rescan"), "", "warning", t("common_yes"), t("common_no"));
            if(confirm) orderChangeStatus(OD_STATUS);

        }else if(OD_STATUS === "W"){
            if(OD_MAP.OD_LAB === 0){
                cm_swAlert(t("order_choiceLab"), "warning", t("common_check"));
                return;
            }

            if(!FL_LIST || FL_LIST.filter(x => x.FL_REFKEY === "DESIGN").length < 1){
                cm_swAlert(t("order_uploadDesignFile"), "warning", t("common_check"));
                return;
            }

            orderChangeStatus(OD_STATUS);
        }else if(OD_STATUS === "F"){
            if(!FL_LIST || FL_LIST.filter(x => x.FL_REFKEY === "DESIGN").length < 1){
                cm_swAlert(t("order_uploadDesignFile"), "warning");
                return;
            }
            
            orderChangeStatus(OD_STATUS);
        } else {
            orderChangeStatus(OD_STATUS);
        }
    }

    async function orderUpdateCall(OD_SEQ, OD_STATUS, formData){
        const response = await asyncOrderUpdate(formData);

        if(resApiCheck(response)){
            setOrderList(produce(prevValue => {
                prevValue.find(x => x.OD_SEQ === OD_SEQ).OD_STATUS = OD_STATUS;
            }));
    
            handleOrderMap(OD_SEQ);

            resResultAlert(response.data.RESULT, t("order_content_6"));
        }
    }

    function orderUpdate(OD_STATUS, designFiles, OD_LAB, OL_LIST, OD_DUEDATE = null, OC_TEXT){
        const {OD_SEQ, OD_UKEY} = orderMap.OD_MAP;

        let fileKeys = [];
        let formData = new FormData();
        formData.append("OD_SEQ", OD_SEQ);
        formData.append("OD_UKEY", OD_UKEY);
        formData.append("OD_STATUS", OD_STATUS);

        if(!cm_isEmpty(designFiles)){
            for(let file of designFiles){
                fileKeys = [...fileKeys, { fileName: file.name, fileRef: file.ref }]
                formData.append("file", file);
            }
            
            formData.append("fileKey", JSON.stringify(fileKeys));
        }
        
        formData.append("OD_LAB", !cm_isEmpty(OD_LAB) ? OD_LAB : orderMap.OD_MAP.OD_LAB);
        formData.append("OL_LIST", JSON.stringify(OL_LIST));
        
        if(OD_STATUS === "A"){
            formData.append("REMAKE", "Y");
            formData.append("OD_DUEDATE", OD_DUEDATE);
            formData.append("OC_TEXT", OC_TEXT);
        }

        orderUpdateCall(OD_SEQ, OD_STATUS, formData);
    }

    async function orderChangeStatus(OD_STATUS, OD_PLAC=null) {
        const {OD_SEQ, OD_UKEY} = orderMap.OD_MAP;

        let data ={
            OD_SEQ: OD_SEQ, 
            OD_STATUS: OD_STATUS, 
            OD_UKEY: OD_UKEY
        }

        if(!cm_isEmpty(OD_PLAC)){
            data.OD_PLAC = "Y";
        }

        const response = await asyncOrderChangeStatus(data);
        if(resApiCheck(response) && !cm_isEmpty(OD_SEQ)){
            handleOrderList();

            handleOrderMap(OD_SEQ);

            resResultAlert(response.data.RESULT, t("order_content_6"));
        }
    }

    async function orderDelete() {
        const OD_SEQ = orderMap.OD_MAP.OD_SEQ;

        let formData = new FormData();
        formData.append("US_SEQ", US_MAP.US_SEQ);
        formData.append("OD_SEQ", OD_SEQ);

        const response = await asyncOrderDelete(formData);
        if(resApiCheck(response)){
            cm_swAlert(t("order_content_7"), "success", t("common_check"));
    
            const newOrderList = [...orderList].filter(x => x.OD_SEQ !== OD_SEQ);
            setOrderList([...newOrderList]);
            setOrderMap({});
        }
    }

    const setPage = (page) => {
        setOrderListFilter(produce(prevValue => {
            prevValue.PAGE_INDEX = page;
        }))
    }

    return(
        <>
            <div className="home-wrap" style={{display:"block"}}>
                { cm_isEmpty(orderMap.OD_MAP) ? 
                    <>
                        <OrderSearch orderListFilter={orderListFilter} setOrderListFilter={setOrderListFilter} orderCnt={orderCnt}/>
                        <OrderList orderList={orderList} orderLineList={orderLineList} handleOrderMap={handleOrderMap}/>
                        <Pagination total={listCount} limit={limit} page={orderListFilter.PAGE_INDEX} setPage={setPage}/>
                    </>
                    : 
                    <OrderDetail orderMap={orderMap} setOrderMap={setOrderMap} orderUpdateCall={orderUpdateCall} handleOrderStatus={handleOrderStatus} handleOrderMap={handleOrderMap} handleSplitModal={handleSplitModal} orderChangeStatus={orderChangeStatus} /> 
                }
            </div>

            {US_MAP.CO_TYPE !== _CO_TYPE_LAB && <PrintQueue />}

            <Modal show={isSplitOpen} onHide={handleSplitModal} centered>
                <OrderSplitModal isOpen={isSplitOpen} handleModal={handleSplitModal} orderMap={orderMap} handleOrderList={handleOrderList} handleOrderMap={handleOrderMap}/>
            </Modal>

            <Modal show={isRemakeOpen} onHide={handleRemakeModal} centered>
                <RemakeModal isOpen={isRemakeOpen} handleModal={handleRemakeModal} orderMap={orderMap} setOrderMap={setOrderMap} orderUpdate={orderUpdate} targetStatus={targetStatus}/>
            </Modal>

            <Modal show={isRepairOpen} onHide={handleRepairModal} centered>
                <RepairModal isOpen={isRepairOpen} handleModal={handleRepairModal} orderMap={orderMap} setOrderMap={setOrderMap} orderUpdate={orderUpdate} targetStatus={targetStatus} handleOrderMap={handleOrderMap}/>
            </Modal>
        </>
    )
}