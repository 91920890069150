import useTranslate from "components/language/useTranslate";
import printComplete from "img/common/ico_print_complete.png";
import printErr from "img/common/ico_print_error.png";
import printIng from "img/common/ico_print_ing.png";
import printWait from "img/common/ico_print_wait.png";
import { cm_dateAddDay, cm_dateMoment, cm_dateToday } from "utils/common";

/* Company Type */
export const _CO_TYPE_CENTER = "Q";
export const _CO_TYPE_CLINIC = "C";
export const _CO_TYPE_LAB = "L";
export const _CO_TYPE_DESIGN = "D";

/* User Type */
export const _US_TYPE_QA = "QA";
export const _US_TYPE_QC = "QC";
export const _US_TYPE_QD = "QD";
export const _US_TYPE_QF = "QF";
export const _US_TYPE_QS = "QS";
export const _US_TYPE_CO = "CO";
export const _US_TYPE_CE = "CE";

/* Comapny Product Minimum cost */
export const _PRODUCT_MINIMU_COST = 1000;

/* Interval Time */
export const _INTERVAL_TIME = 60000;

/* LIMIT_LENGTH */
export const _LIMIT_COMMENT_LENGTH = 200;
export const _LIMIT_MEMO_LENGTH = 10000;

/* Default page limit */
export const _LIMIT_PAGE_ROW = 10;

/* Limit Rescan,Remake count */
export const _LIMIT_PREV_CNT = 255;

/*
    API
 */
export const API = {
    //COMMON
    INDEX: "/common/index",
    SIGN_IN: "/common/signIn",
    SIGN_UP: "/common/signUp",
    TOKEN: "/common/token",
    FIND_PWD: "/common/findPwd",
    TOKEN_USER: "/common/tokenUser",
    DELETE_FILE: "/common/deleteFile",
    ENCRYPT_URL: "/common/encryptUrl",
    EMAIL_AUTH: "/common/emailAuth",

    //CONFIG
    CONFIG_LIST: "/config/configList",
    CONFIG_READ: "/config/configRead",
    CONFIG_WRITE: "/config/configWrite",
    CONFIG_WRITE_LIST: "/config/configWriteList",
    CONFIG_UPDATE: "/config/configUpdate",
    CONFIG_DELETE: "/config/configDelete",

    //COMPANY
    COMPANY_LIST: "/company/companyList",
    COMPANY_READ: "/company/companyRead",
    COMPANY_WRITE: "/company/companyWrite",
    COMPANY_DELETE: "/company/companyDelete",
    //COMPANY PLAN
    COMPANY_PALN_LIST: "/company/planList",
    COMPANY_PALN_READ: "/company/planRead",
    COMPANY_PALN_WRITE: "/company/planWrite",
    COMPANY_PALN_DELETE: "/company/planDelete",
    //COMPANY DEVICE
    COMPANY_DEVICE_LIST: "/company/deviceList",
    COMPANY_DEVICE_WRITE: "/company/deviceWrite",
    //COMPANY PRODUCT
    COMPANY_PRODUCT_LIST: "/company/productList",
    COMPANY_PRODUCT_WRITE: "/company/productWrite",
    //COMPANY PAYMENT
    COMPANY_PAYMENT_READ: "/company/paymentRead",
    COMPANY_PAYMENT_WRITE: "/company/paymentWrite",
    COMPANY_PAYMENT_UPDATE: "/company/paymentUpdate",
    COMPANY_PAYMENT_DELETE: "/company/paymentDelete",
    //COMPANY DEVICE SNAPSHOT
    COMPANY_DEVICE_SNAPSHOT_LIST: "/company/deviceSnapshotList",

    //USER
    USER_LIST: "/user/userList",
    USER_READ: "/user/userRead",
    USER_WRITE: "/user/userWrite",
    USER_UPDATE: "/user/userUpdate",
    USER_UPDATE_PWD: "/user/userUpdatePwd",
    USER_VALIDATE_PWD: "/user/userValidatePwd",
    USER_DELETE: "/user/userDelete",
    USER_OPTION_LIST: "/user/optionList",
    USER_OPTION_WRITE: "/user/optionWrite",
    USER_OPTION_DELETE: "/user/optionDelete",

    //ORDER
    ORDER_LIST: "/order/orderList",
    ORDER_READ: "/order/orderRead",
    ORDER_CREATE: "/order/orderCreate",
    ORDER_UPDATE: "/order/orderUpdate",
    ORDER_UPDATE_INFO: "/order/orderUpdateInfo",
    ORDER_REPAIR: "/order/orderRepair",
    ORDER_REPAIR_CANCEL: "/order/orderRepairCancel",
    ORDER_SPLIT: "/order/orderSplit",
    ORDER_CHANGE_STATUS: "/order/orderChangeStatus",
    ORDER_DELETE: "/order/orderDelete",
    ORDER_UPLOAD_FILE: "/order/orderUploadFile",
    ORDER_TEMPFILE: "/order/orderTempFile",
    ORDER_COMMENT_LIST: "/order/commentList",
    ORDER_COMMENT_WRITE: "/order/commentWrite",
    ORDER_COMMENT_DELETE: "/order/commentDelete",
    ORDER_PRINT_LIST: "/order/printList",
    ORDER_PRINT_READ: "/order/printRead",
    ORDER_PRINT_UPDATE: "/order/printUpdate",
    ORDER_PRINT_DELETE: "/order/printDelete",
    ORDER_PRINT_DOWNLOAD: "/order/printDownload",
    ORDER_FILE_UPLOAD: "/order/fileUpload",
    ORDER_AUTO_SHIPPING: "/order/autoShipping",
    ORDER_ADJUST_WIRTE: "/order/adjustWrite",
    ORDER_ADJUST_LIST: "/order/adjustList",

    //PLAN
    PLAN_LIST: "/plan/planList",
    PLAN_READ: "/plan/planRead",
    PLAN_WRITE: "/plan/planWrite",
    PLAN_DELETE: "/plan/planDelete",

    //PRODUCT
    PRODUCT_LIST: "/product/productList",
    PRODUCT_READ: "/product/productRead",
    PRODUCT_WRITE: "/product/productWrite",
    PRODUCT_DELETE: "/product/productDelete",

    //DEVICE
    DEVICE_LIST: "/device/deviceList",
    DEVICE_READ: "/device/deviceRead",
    DEVICE_WRITE: "/device/deviceWrite",
    DEVICE_DELETE: "/device/deviceDelete",

    //NOTICE
    NOTICE_COUNT: "/notice/noticeCount",
    NOTICE_LIST: "/notice/noticeList",
    NOTICE_READ: "/notice/noticeRead",
    NOTICE_CONFIRM_ALL: "/notice/noticeConfirmAll",
    NOTICE_DELETE: "/notice/noticeDelete",

    //ACCOUNT
    ACCOUNT_LIST: "/account/accountList",
    ACCOUNT_READ: "/account/accountRead",
    ACCOUNT_INVOICE: "/account/accountInvoice",
    ACCOUNT_SEND_INVOICE: "/account/accountSendInvoice",
    ACCOUNT_REALTIME: "/account/accountRealTime",
    ACCOUNT_TRANSACTION_LIST: "/account/accountTransactionList",
    ACCOUNT_TRANSACTION_WRITE: "/account/accountTransctionWrite",
    ACCOUNT_ADJUST_LIST: "/account/accountAdjustList",
    ACCOUNT_ADJUST_WRITE: "/account/accountAdjustWrite",
    ACCOUNT_REMAIN_LIST: "/account/remainAmountList",
    ACCOUNT_REMAIN_AMOUNT: "/account/remainAmount",

    //HOLIDAY
    HOLIDAY_LIST: "/holiday/holidayList",
    HOLIDAY_WRITE: "/holiday/holidayWrite",
    HOLIDAY_DELETE: "/holiday/holidayDelete",

    //BOARD
    BOARD_LIST: "/board/boardList",
    BOARD_READ: "/board/boardRead",
    BOARD_WRITE: "/board/boardWrite",
    BOARD_DELETE: "/board/boardDelete",
    BOARD_COMMENT_LIST: "/board/commentList",
    BOARD_COMMENT_WRITE: "/board/commentWrite",
    BOARD_COMMENT_DELETE: "/board/commentDelete",
};

/*
    Protect Route roles data 
    LEVEL(필수), TYPE(선택)
    url 접속시 사용자 LEVEL 이상, 해당 TYPE만 접속 가능
    TYPE 설정시 LEVEL, TYPE 두개 조건 만족하는 member만 접속
*/
export const ROLES = {
    home: {
        ALLOW_LEVEL: 1,
    },
    mypage: {
        ALLOW_LEVEL: 1,
    },
    order: {
        ALLOW_LEVEL: 1,
        ALLOW_TYPE_LIST: ["QA", "QC", "QD", "CO", "CE", "CS"],
    },
    orderwrite: {
        ALLOW_LEVEL: 1,
        ALLOW_TYPE_LIST: ["QA", "QC", "QD", "CO", "CE", "CS"],
        PLAN: "Y",
    },
    delivery: {
        ALLOW_LEVEL: 1,
    },
    payment: {
        ALLOW_LEVEL: 1,
        ALLOW_TYPE_LIST: ["CO"],
    },
    staff: {
        ALLOW_LEVEL: 9,
        ALLOW_TYPE_LIST: ["CO"],
    },
    admin_accountmanage: {
        ALLOW_LEVEL: 11,
        ALLOW_TYPE_LIST: ["QA", "QC", "QF", "QS"],
    },
    admin_membermanage: {
        ALLOW_LEVEL: 11,
        ALLOW_TYPE_LIST: ["QA", "QC", "QS"],
    },
    admin_planmanage: {
        ALLOW_LEVEL: 11,
        ALLOW_TYPE_LIST: ["QA", "QC", "QS"],
    },
    admin_productmanage: {
        ALLOW_LEVEL: 11,
        ALLOW_TYPE_LIST: ["QA", "QC"],
    },
    admin_devicemanage: {
        ALLOW_LEVEL: 11,
        ALLOW_TYPE_LIST: ["QA", "QC", "QS"],
    },
    admin_holidaymanage: {
        ALLOW_LEVEL: 11,
        ALLOW_TYPE_LIST: ["QA", "QC"],
    },
};

/*
    navigationList
    QA : system admin ; 개발팀
    QC : center master ; 기공팀장
    QF : financial manager ; 재무/회계팀
    QD : deginer ; 기공팀원
    QS : sales man ; 영업팀
    CO : company owner
    CE : company employee
    MN : ?
*/
export const NAVIS = () => {
    const t = useTranslate();
    const navies = [
        {
            coType: ["Q", "C", "L", "D"],
            userType: ["QA", "QC", "QF", "QD", "QS", "CO", "CE"],
            title: t("home"), // HOME
            to: "home",
            liClass: null,
            navClass: "nav1",
        },
        {
            coType: ["Q", "C", "D"],
            userType: ["QA", "QC", "QD", "CO", "CE"],
            title: t("order_orderAdd"), // 주문등록
            to: "order/orderWrite",
            liClass: null,
            navClass: "nav11",
        },
        {
            coType: ["Q", "C", "L", "D"],
            userType: ["QA", "QC", "QD", "CO", "CE"],
            title: t("common_orderList"), // 주문목록
            to: "order",
            liClass: null,
            navClass: "nav5",
        },
        {
            coType: ["Q", "C", "L"],
            userType: ["QA", "QC", "QD", "CO", "CE"],
            title: t("common_delivery"), //배송조회
            to: "delivery",
            liClass: null,
            navClass: "nav4",
        },
        {
            coType: ["Q", "C", "L", "D"],
            userType: ["CO"],
            title: t("common_payment"), // 정산
            to: "payment",
            liClass: null,
            navClass: "nav3",
        },
        {
            coType: ["Q", "C", "L", "D"],
            userType: ["CO"],
            title: t("common_memberMg"), // 사용자
            to: "staff",
            liClass: null,
            navClass: "nav6",
        },
        {
            coType: ["Q"],
            userType: ["QA", "QC", "QF", "QS"],
            title: t("payment_mg"), // 정산 manage
            to: "accountmanage",
            liClass: null,
            navClass: "nav3",
        },
        {
            coType: ["Q"],
            userType: ["QA", "QC", "QS"],
            title: t("common_memberMg"), // 사용자 manage
            to: "companymanage",
            liClass: null,
            navClass: "nav6",
        },
        {
            coType: ["Q"],
            userType: ["QA", "QC", "QS"],
            title: t("common_plan"), // 플랜 manage
            to: "planmanage",
            liClass: null,
            navClass: "nav8",
        },
        {
            coType: ["Q"],
            userType: ["QA", "QC"],
            title: t("common_prod"), //제품 manage
            to: "productmanage",
            liClass: null,
            navClass: "nav7",
        },
        {
            coType: ["Q"],
            userType: ["QA", "QC", "QS"],
            title: t("common_equip"), //장비 manage
            to: "devicemanage",
            liClass: null,
            navClass: "nav9",
        },
        {
            coType: ["Q"],
            userType: ["QA", "QC"],
            title: t("holiday"), // 휴일 manage
            to: "holidaymanage",
            liClass: null,
            navClass: "nav10",
        },
        {
            coType: ["Q", "C", "L", "D"],
            userType: ["QA", "QC", "QF", "QD", "QS", "CO", "CE"],
            title: t("common_board"), //게시판
            to: "board",
            liClass: null,
            navClass: "nav2",
        },
    ];

    return navies;
};

/*
    myPagelist
*/
export const MY_PAGE_LIST = () => {
    const t = useTranslate();
    const myPageList = [
        {
            key: "UserInfo",
            title: t("myPage_info"), //계정 정보
            coType: ["Q", "C", "L", "D"],
            type: ["QA", "QC", "QF", "QM", "QD", "QS", "CO", "CE"],
        },
        {
            key: "UserOption",
            title: t("myPage_orderMg"), //주문 관리
            coType: ["Q", "C", "D"],
            type: ["CO", "CE"],
        },
        {
            key: "CompanyPlan",
            title: t("myPage_contractInfo"), //계약 정보
            coType: ["Q", "C", "L", "D"],
            type: ["CO"], //
        },
        {
            key: "CompanyProduct",
            title: t("myPage_dtlPstheticMg"), //제작품목 관리
            coType: ["Q", "L"],
            type: ["CO"],
        },
        {
            key: "CompanyPayment",
            title: t("myPage_payMg"), //결제 관리
            coType: ["C", "D"],
            type: ["CO"],
        },
        {
            key: "UserPwd",
            title: t("myPage_pwChg"), //비밀번호 변경
            coType: ["Q", "C", "L", "D"],
            type: ["QA", "QC", "QF", "QM", "QD", "QS", "CO", "CE"],
        },
    ];

    return myPageList;
};

/* orderContext init*/
export const ORDER_INIT = () => {
    const orderContextInit = {
        CO_SEQ: 0,
        OD_SEQ: 0,
        OD_STATUS: "M",
        OD_DUEDATE: cm_dateMoment(cm_dateAddDay(cm_dateToday(), 6)),
        OD_NAME: "",
        OD_FILE_SCAN: [],
        OL_LIST: [],
        OD_BRIDGE: [],
        OD_CONTACT: "1",
        OD_EMBRASURE: "1",
        OD_HOOK: "1",
        OD_PONTIC: "1",
        OD_MEMO: "",
        OD_DC: "N",
        OD_DESIGNER: 0,
        OD_CHECK: {
            info: false,
            product: false,
            option: false,
            check: false,
        },
        TEMP_KEY: "",
    };
    return orderContextInit;
};

/* prosthetic Options */
export const PROSTHETIC_OPTIONS = () => {
    const t = useTranslate();

    const prosthetic = {
        contact: [
            {
                title: t("order_contactWeakly"), //약하게
                value: "0",
            },
            {
                title: t("order_contactNomally"), //보통
                value: "1",
            },
            {
                title: t("order_contactTightly"), //타이트하게
                value: "2",
            },
        ],
        embrasure: [
            {
                title: t("order_embrasureOpen"), //Open
                value: "0",
            },
            {
                title: t("order_embrasureClose"), //Close
                value: "1",
            },
        ],
        hook: [
            {
                title: t("order_hookUse"), //사용
                value: "0",
            },
            {
                title: t("order_hookNotUse"), //미사용
                value: "1",
            },
        ],
        ponticType: [
            {
                title: t("order_pontic_saddle"), //Saddle
                value: "0",
            },
            {
                title: t("order_pontic_ridgeLap"), //ridge lap
                value: "1",
            },
            {
                title: t("order_pontic_conical"), //conical
                value: "2",
            },
            {
                title: t("order_pontic_ovate"), //ovate
                value: "3",
            },
            {
                title: t("order_pontic_hygienic"), //hygienic
                value: "4",
            },
        ],
    };
    return prosthetic;
};

export const ORDER_WRITE_TAB = () => {
    const t = useTranslate();
    const orderWriteTab = [
        {
            idx: 0,
            key: "info",
            title: t("order_write_tap_info"), //환자 정보
        },
        {
            idx: 1,
            key: "product",
            title: t("order_write_tap_prosthetics"), //치식/보철
        },
        {
            idx: 2,
            key: "option",
            title: t("order_write_tap_option"), //제작 옵션
        },
        {
            idx: 3,
            key: "check",
            title: t("order_write_tap_check"), //주문 확인
        },
    ];
    return orderWriteTab;
};

export const ORDER_STEP = () => {
    const t = useTranslate();

    const orderStep = [
        {
            key: "M",
            text: t("order_step_memo"),
        },
        {
            key: "D",
            text: t("order_step_design"),
        },
        {
            key: "F",
            text: t("order_step_designconfirm"),
        },
        {
            key: "W",
            text: t("order_step_wait"),
        },
        {
            key: "K",
            text: t("order_step_make"),
        },
        {
            key: "S",
            text: t("order_step_shipping"),
        },
        {
            key: "C",
            text: t("order_step_complete"),
        },
        {
            key: "R",
            text: t("order_step_rescan"),
        },
        {
            key: "A",
            text: t("order_step_remake"),
        },
        {
            key: "E",
            text: t("order_step_cancle"),
        },
        {
            key: "V",
            text: t("order_step_save"),
        },
    ];
    return orderStep;
};

export const ORDER_STATUS = () => {
    const t = useTranslate();
    const orderStatus = [
        {
            key: "N",
            text: "NONE",
            coType: [],
            col: "",
        },
        {
            key: "R",
            text: t("order_status_rescan"),
            coType: ["Q", "C", "D"],
            col: "OD_RESCAN",
        },
        {
            key: "A",
            text: t("order_status_remake"),
            coType: ["Q", "C"],
            col: "OD_REMAKE",
        },
        {
            key: "M",
            text: t("order_status_memo"),
            coType: ["Q", "C", "D"],
            col: "OD_MEMO",
        },
        {
            key: "D",
            text: t("order_status_design"),
            coType: ["Q", "C", "D"],
            col: "OD_DESIGN",
        },
        {
            key: "F",
            text: t("order_status_designconfirm"),
            coType: ["Q", "D"],
            col: "OD_DC",
        },
        {
            key: "W",
            text: t("order_status_wait"),
            coType: ["Q", "C", "L"],
            col: "OD_WAIT",
        },
        {
            key: "K",
            text: t("order_status_make"),
            coType: ["Q", "C", "L"],
            col: "OD_MAKING",
        },
        {
            key: "S",
            text: t("order_status_shipping"),
            coType: ["Q", "C", "L"],
            col: "OD_SHIPPING",
        },
        {
            key: "C",
            text: t("order_status_complete"),
            coType: ["Q", "C", "L", "D"],
            col: "OD_COMPLETE",
        },
    ];
    return orderStatus;
};

export const ORDER_ISSUE = () => {
    const t = useTranslate();
    const orderIssue = [
        {
            key: "print",
            type: "P",
            text: t("common_print"),
            coType: ["Q", "C"],
            col: "FT_PRINT",
        },
        {
            key: "rescan",
            type: "S",
            text: t("common_rescan"),
            coType: ["Q", "C", "D"],
            col: "FT_RESCAN",
        },
        {
            key: "remake",
            type: "R",
            text: t("common_remake"),
            coType: ["Q", "C", "L"],
            col: "FT_REMAKE",
        },
        {
            key: "repair",
            type: "A",
            text: t("common_repair"),
            coType: ["Q", "C", "L"],
            col: "FT_REPAIR",
        },
    ];
    return orderIssue;
};

export const ORDER_COMMENT = () => {
    const t = useTranslate();
    const orderComment = [
        {
            key: "rescan",
            type: "S",
            text: t("common_rescan"),
        },
        {
            key: "remake",
            type: "A",
            text: t("common_remake"),
        },
        {
            key: "repair",
            type: "P",
            text: t("common_repair"),
        },
    ];
    return orderComment;
};

export const PLA_STATUS = () => {
    const t = useTranslate();
    const plaStatus = [
        {
            key: "N",
            text: "NONE",
            class: "",
        },
        {
            key: "M",
            text: t("print_status_standby"),
            class: "wait",
        },
        {
            key: "R",
            text: t("print_status_standby"),
            class: "wait",
        },
        {
            key: "A",
            text: t("print_status_standby"),
            class: "wait",
        },
        {
            key: "D",
            text: t("print_status_standby"),
            class: "wait",
        },
        {
            key: "W",
            text: t("print_status_printing"),
            class: "ing",
        },
        {
            key: "K",
            text: t("print_status_printing"),
            class: "ing",
        },
        {
            key: "S",
            text: t("print_status_complete"),
            class: "complete",
        },
        {
            key: "E",
            text: t("print_status_error"),
            class: "error",
        },
        {
            key: "C",
            text: t("print_status_complete"),
            class: "complete",
        },
    ];

    return plaStatus;
};

export const PRINT_STATUS = () => {
    const t = useTranslate();
    const printStatus = [
        {
            status: "W",
            text: t("print_status_standby"),
            class: "item alert",
            img: printWait,
        },
        {
            status: "D",
            text: t("print_status_download"),
            class: "item alert",
            img: printWait,
        },
        {
            status: "P",
            text: t("print_status_printing"),
            class: "item ing active",
            img: printIng,
        },
        {
            status: "E",
            text: t("print_status_error"),
            class: "item stop",
            img: printErr,
        },
        {
            status: "C",
            text: t("print_status_complete"),
            class: "item alert",
            img: printComplete,
        },
    ];

    return printStatus;
};

export const COMPANY_DEVICE_STATUS = () => {
    const t = useTranslate();
    const companyDeviceStatus = [
        {
            status: "M",
            text: t("cd_status_memo"),
        },
        {
            status: "P",
            text: t("cd_status_preparing"),
        },
        {
            status: "U",
            text: t("cd_status_inuse"),
        },
        {
            status: "R",
            text: t("cd_status_return"),
        },
        {
            status: "E",
            text: t("cd_status_expired"),
        },
        {
            status: "C",
            text: t("cd_status_cancel"),
        },
    ];

    return companyDeviceStatus;
};

export const AT_STATUS = () => {
    const t = useTranslate();

    const transactionStatus = [
        {
            key: "M",
            text: t("cd_status_memo"),
        },
        {
            key: "C",
            text: t("common_complet"),
        },
    ];
    return transactionStatus;
};

export const TOOTH_NOTATION = () => {
    const NOTATION = process.env.REACT_APP_NOTATION;

    let SVG_MAXILLA, SVG_MANDIBLE, BRIDGE_MAXILLA, BRIDGE_MANDIBLE, REF_NUM;
    if (NOTATION === "FDI") {
        SVG_MAXILLA = svgTooth_maxilla;
        SVG_MANDIBLE = svgTooth_mandible;
        BRIDGE_MAXILLA = bridgeMaxilla;
        BRIDGE_MANDIBLE = bridgeMandible;
        REF_NUM = 30;
    } else {
        SVG_MAXILLA = svgTooth_maxilla_us;
        SVG_MANDIBLE = svgTooth_mandible_us;
        BRIDGE_MAXILLA = bridgeMaxilla_us;
        BRIDGE_MANDIBLE = bridgeMandible_us;
        REF_NUM = 16;
    }

    return [SVG_MAXILLA, SVG_MANDIBLE, BRIDGE_MAXILLA, BRIDGE_MANDIBLE, REF_NUM];
};

const svgTooth_maxilla = [
    {
        toothNum: "18",
        pathId: "Vector",
        d: "M25.4615 11C25.4615 11 54 11 54 32.5C54 54 41.7692 54 41.7692 54C41.7692 54 29.5385 54 29.5385 45.4C29.5385 45.4 29.5385 54 17.3077 54C5.07692 54 1 36.8 1 32.5C1 28.2 5.07692 11 25.4615 11V11Z",
        x: 18.4362,
        y: 40.3182,
    },
    {
        toothNum: "17",
        pathId: "Vector_2",
        d: "M102.381 48.2504C102.381 48.2504 106.921 57 116.814 57C126.707 57 134.815 48.2504 134.815 43.8755C134.815 39.5007 136.316 26.2013 130.761 17.6266C126.504 11.0644 116.976 10.7582 106.435 8.87701C95.8936 6.99584 87.2172 8.00204 82.1087 13.2518C75.865 19.6828 74 30.7511 74 30.7511C74 30.7511 74 48.2504 90.2175 52.6252C90.2175 52.6252 98.3262 52.6252 102.381 48.2504V48.2504Z",
        x: 97.0732,
        y: 40.3182,
    },
    {
        toothNum: "16",
        pathId: "Vector_3",
        d: "M187.684 47.2727C187.684 47.2727 192.709 56 202.432 56C212.156 56 220.98 41.9927 218.611 31.5636C216.241 21.1345 208.07 8 199.899 8H171.301C170.116 8 168.645 8.69818 167.093 9.74546C161.823 13.1927 158.228 18.9527 156.798 25.3673C155.858 29.5564 155 34.5309 155 38.5455C155 47.2727 163.171 56 167.256 56C171.342 56 183.598 56 187.684 47.2727V47.2727Z",
        x: 176.913,
        y: 36.9688,
    },
    {
        toothNum: "15",
        pathId: "Vector_4",
        d: "M261.502 9.77147C268.876 14.2466 281 24.7891 281 45.7879C281 45.7879 268.501 63 260.168 63C251.836 63 239.337 54.3939 239.337 45.7879C239.337 38.2576 236.17 17.603 249.253 9.64238C253.044 7.36177 257.71 7.49086 261.46 9.8145L261.502 9.77147Z",
        x: 250.36,
        y: 41.3182,
    },
    {
        toothNum: "14",
        pathId: "Vector_5",
        d: "M317.907 7.043C323.583 7.043 328.854 10.2252 331.651 15.4715C335.057 21.8358 339.314 31.0383 341.544 40.4988C345.599 57.6998 321.272 62 321.272 62C321.272 62 301 57.6998 301 44.7991C301 31.8984 302.46 18.9977 305.784 14.6974C308.338 11.3862 312.312 9.19312 314.542 7.90305C315.596 7.30102 316.731 7 317.907 7V7.043Z",
        x: 311.087,
        y: 40.3182,
    },
    {
        toothNum: "13",
        pathId: "Vector_6",
        d: "M380.477 1.86631C382.471 0.763425 384.749 0.721007 386.823 1.69663C391.664 3.98723 401.305 10.3924 404.315 26.0449C408.383 47.2541 404.315 55.7378 400.247 59.9797C397.603 62.7369 393.291 65.4517 390.525 67.0636C388.898 67.9968 386.986 68.2513 385.196 67.7423C379.46 66.0455 366.728 61.2946 363.596 51.496C359.528 38.7704 363.596 17.5612 371.732 9.07745C375.515 5.13253 378.444 3.01161 380.477 1.86631V1.86631Z",
        x: 374.156,
        y: 40.3182,
    },
    {
        toothNum: "12",
        pathId: "Vector_7",
        d: "M434.481 57H458.6C458.6 57 463 57 463 48.5123C463 40.0246 463 10.3176 442.629 1.82991C422.258 -6.6578 426.333 52.7561 426.333 52.7561C426.333 52.7561 426.333 57 434.481 57Z",
        x: 435.332,
        y: 34.3182,
    },
    {
        toothNum: "11",
        pathId: "Vector_8",
        d: "M486.798 62.0392C488.104 65.182 496.23 66 499.456 66H527.917C527.917 66 532 66 532 61.6947C532 57.3895 532 5.72623 507.5 1.42096C483 -2.88431 483 27.2526 483 27.2526C483 27.2526 483 53.0842 486.798 62.0392Z",
        x: 500.097,
        y: 39.3182,
    },
    {
        toothNum: "21",
        pathId: "Vector_9",
        d: "M597.203 62.0392C595.896 65.182 587.77 66 584.544 66H556.083C556.083 66 552 66 552 61.6947C552 57.3895 552 5.72623 576.5 1.42096C601 -2.88431 601 27.2526 601 27.2526C601 27.2526 601 53.0842 597.203 62.0392Z",
        x: 567.161,
        y: 39.3182,
    },
    {
        toothNum: "22",
        pathId: "Vector_10",
        d: "M649.519 57H625.4C625.4 57 621 57 621 48.5123C621 40.0246 621 10.3176 641.371 1.82991C661.742 -6.6578 657.667 52.7561 657.667 52.7561C657.667 52.7561 657.667 57 649.519 57Z",
        x: 628.228,
        y: 34.3182,
    },
    {
        toothNum: "23",
        pathId: "Vector_11",
        d: "M704.103 1.86631C702.064 0.763425 699.734 0.721007 697.613 1.69663C692.662 3.98723 682.802 10.3924 679.723 26.0449C675.563 47.2541 679.723 55.7378 683.884 59.9797C686.588 62.7369 690.998 65.4517 693.827 67.0636C695.491 67.9968 697.446 68.2513 699.277 67.7423C705.143 66.0455 718.164 61.2946 721.368 51.496C725.528 38.7704 721.368 17.5612 713.047 9.07745C709.178 5.13253 706.183 3.01161 704.103 1.86631V1.86631Z",
        x: 689.045,
        y: 40.3182,
    },
    {
        toothNum: "24",
        pathId: "Vector_12",
        d: "M767.093 1.043C761.417 1.043 756.146 4.22518 753.349 9.47146C749.943 15.8358 745.686 25.0383 743.456 34.4988C739.401 51.6998 763.728 56 763.728 56C763.728 56 784 51.6998 784 38.7991C784 25.8984 782.54 12.9977 779.216 8.69742C776.662 5.38624 772.688 3.19312 770.458 1.90305C769.404 1.30102 768.269 1 767.093 1V1.043Z",
        x: 753.087,
        y: 34.3182,
    },
    {
        toothNum: "25",
        pathId: "Vector_13",
        d: "M823.034 2.77147C815.835 7.24663 804 17.7891 804 38.7879C804 38.7879 816.201 56 824.336 56C832.47 56 844.671 47.3939 844.671 38.7879C844.671 31.2576 847.762 10.603 834.991 2.64238C831.29 0.36177 826.735 0.490861 823.075 2.8145L823.034 2.77147Z",
        x: 814.227,
        y: 34.3182,
    },
    {
        toothNum: "26",
        pathId: "Vector_14",
        d: "M896.316 41.2727C896.316 41.2727 891.291 50 881.568 50C871.844 50 863.02 35.9927 865.389 25.5636C867.759 15.1345 875.93 2 884.101 2H912.699C913.884 2 915.355 2.69818 916.907 3.74546C922.177 7.19273 925.772 12.9527 927.202 19.3673C928.142 23.5564 929 28.5309 929 32.5455C929 41.2727 920.829 50 916.744 50C912.658 50 900.402 50 896.316 41.2727V41.2727Z",
        x: 886.081,
        y: 31.3182,
    },
    {
        toothNum: "27",
        pathId: "Vector_15",
        d: "M981.619 41.2504C981.619 41.2504 977.079 50 967.186 50C957.293 50 949.185 41.2504 949.185 36.8755C949.185 34.3819 947.684 19.2013 953.239 10.6266C957.496 4.06442 967.024 3.75818 977.565 1.87701C988.106 -0.00416477 996.783 1.00204 1001.89 6.25182C1008.13 12.6828 1010 23.7511 1010 23.7511C1010 23.7511 1010 41.2504 993.783 45.6252C993.783 45.6252 985.674 45.6252 981.619 41.2504V41.2504Z",
        x: 969.96,
        y: 31.3182,
    },
    {
        toothNum: "28",
        pathId: "Vector_16",
        d: "M1059.08 1C1059.08 1 1030 1 1030 22.5C1030 44 1042.46 44 1042.46 44C1042.46 44 1054.92 44 1054.92 35.4C1054.92 35.4 1054.92 44 1067.38 44C1079.85 44 1084 26.8 1084 22.5C1084 18.2 1079.85 1 1059.08 1V1Z",
        x: 1047.32,
        y: 28.3182,
    },
];

const svgTooth_maxilla_us = [
    {
        toothNum: "1",
        pathId: "Vector",
        d: "M25.4615 11C25.4615 11 54 11 54 32.5C54 54 41.7692 54 41.7692 54C41.7692 54 29.5385 54 29.5385 45.4C29.5385 45.4 29.5385 54 17.3077 54C5.07692 54 1 36.8 1 32.5C1 28.2 5.07692 11 25.4615 11V11Z",
        x: 18.4362,
        y: 40.3182,
    },
    {
        toothNum: "2",
        pathId: "Vector_2",
        d: "M102.381 48.2504C102.381 48.2504 106.921 57 116.814 57C126.707 57 134.815 48.2504 134.815 43.8755C134.815 39.5007 136.316 26.2013 130.761 17.6266C126.504 11.0644 116.976 10.7582 106.435 8.87701C95.8936 6.99584 87.2172 8.00204 82.1087 13.2518C75.865 19.6828 74 30.7511 74 30.7511C74 30.7511 74 48.2504 90.2175 52.6252C90.2175 52.6252 98.3262 52.6252 102.381 48.2504V48.2504Z",
        x: 97.0732,
        y: 40.3182,
    },
    {
        toothNum: "3",
        pathId: "Vector_3",
        d: "M187.684 47.2727C187.684 47.2727 192.709 56 202.432 56C212.156 56 220.98 41.9927 218.611 31.5636C216.241 21.1345 208.07 8 199.899 8H171.301C170.116 8 168.645 8.69818 167.093 9.74546C161.823 13.1927 158.228 18.9527 156.798 25.3673C155.858 29.5564 155 34.5309 155 38.5455C155 47.2727 163.171 56 167.256 56C171.342 56 183.598 56 187.684 47.2727V47.2727Z",
        x: 176.913,
        y: 36.9688,
    },
    {
        toothNum: "4",
        pathId: "Vector_4",
        d: "M261.502 9.77147C268.876 14.2466 281 24.7891 281 45.7879C281 45.7879 268.501 63 260.168 63C251.836 63 239.337 54.3939 239.337 45.7879C239.337 38.2576 236.17 17.603 249.253 9.64238C253.044 7.36177 257.71 7.49086 261.46 9.8145L261.502 9.77147Z",
        x: 250.36,
        y: 41.3182,
    },
    {
        toothNum: "5",
        pathId: "Vector_5",
        d: "M317.907 7.043C323.583 7.043 328.854 10.2252 331.651 15.4715C335.057 21.8358 339.314 31.0383 341.544 40.4988C345.599 57.6998 321.272 62 321.272 62C321.272 62 301 57.6998 301 44.7991C301 31.8984 302.46 18.9977 305.784 14.6974C308.338 11.3862 312.312 9.19312 314.542 7.90305C315.596 7.30102 316.731 7 317.907 7V7.043Z",
        x: 311.087,
        y: 40.3182,
    },
    {
        toothNum: "6",
        pathId: "Vector_6",
        d: "M380.477 1.86631C382.471 0.763425 384.749 0.721007 386.823 1.69663C391.664 3.98723 401.305 10.3924 404.315 26.0449C408.383 47.2541 404.315 55.7378 400.247 59.9797C397.603 62.7369 393.291 65.4517 390.525 67.0636C388.898 67.9968 386.986 68.2513 385.196 67.7423C379.46 66.0455 366.728 61.2946 363.596 51.496C359.528 38.7704 363.596 17.5612 371.732 9.07745C375.515 5.13253 378.444 3.01161 380.477 1.86631V1.86631Z",
        x: 374.156,
        y: 40.3182,
    },
    {
        toothNum: "7",
        pathId: "Vector_7",
        d: "M434.481 57H458.6C458.6 57 463 57 463 48.5123C463 40.0246 463 10.3176 442.629 1.82991C422.258 -6.6578 426.333 52.7561 426.333 52.7561C426.333 52.7561 426.333 57 434.481 57Z",
        x: 435.332,
        y: 34.3182,
    },
    {
        toothNum: "8",
        pathId: "Vector_8",
        d: "M486.798 62.0392C488.104 65.182 496.23 66 499.456 66H527.917C527.917 66 532 66 532 61.6947C532 57.3895 532 5.72623 507.5 1.42096C483 -2.88431 483 27.2526 483 27.2526C483 27.2526 483 53.0842 486.798 62.0392Z",
        x: 500.097,
        y: 39.3182,
    },
    {
        toothNum: "9",
        pathId: "Vector_9",
        d: "M597.203 62.0392C595.896 65.182 587.77 66 584.544 66H556.083C556.083 66 552 66 552 61.6947C552 57.3895 552 5.72623 576.5 1.42096C601 -2.88431 601 27.2526 601 27.2526C601 27.2526 601 53.0842 597.203 62.0392Z",
        x: 567.161,
        y: 39.3182,
    },
    {
        toothNum: "10",
        pathId: "Vector_10",
        d: "M649.519 57H625.4C625.4 57 621 57 621 48.5123C621 40.0246 621 10.3176 641.371 1.82991C661.742 -6.6578 657.667 52.7561 657.667 52.7561C657.667 52.7561 657.667 57 649.519 57Z",
        x: 628.228,
        y: 34.3182,
    },
    {
        toothNum: "11",
        pathId: "Vector_11",
        d: "M704.103 1.86631C702.064 0.763425 699.734 0.721007 697.613 1.69663C692.662 3.98723 682.802 10.3924 679.723 26.0449C675.563 47.2541 679.723 55.7378 683.884 59.9797C686.588 62.7369 690.998 65.4517 693.827 67.0636C695.491 67.9968 697.446 68.2513 699.277 67.7423C705.143 66.0455 718.164 61.2946 721.368 51.496C725.528 38.7704 721.368 17.5612 713.047 9.07745C709.178 5.13253 706.183 3.01161 704.103 1.86631V1.86631Z",
        x: 689.045,
        y: 40.3182,
    },
    {
        toothNum: "12",
        pathId: "Vector_12",
        d: "M767.093 1.043C761.417 1.043 756.146 4.22518 753.349 9.47146C749.943 15.8358 745.686 25.0383 743.456 34.4988C739.401 51.6998 763.728 56 763.728 56C763.728 56 784 51.6998 784 38.7991C784 25.8984 782.54 12.9977 779.216 8.69742C776.662 5.38624 772.688 3.19312 770.458 1.90305C769.404 1.30102 768.269 1 767.093 1V1.043Z",
        x: 753.087,
        y: 34.3182,
    },
    {
        toothNum: "13",
        pathId: "Vector_13",
        d: "M823.034 2.77147C815.835 7.24663 804 17.7891 804 38.7879C804 38.7879 816.201 56 824.336 56C832.47 56 844.671 47.3939 844.671 38.7879C844.671 31.2576 847.762 10.603 834.991 2.64238C831.29 0.36177 826.735 0.490861 823.075 2.8145L823.034 2.77147Z",
        x: 814.227,
        y: 34.3182,
    },
    {
        toothNum: "14",
        pathId: "Vector_14",
        d: "M896.316 41.2727C896.316 41.2727 891.291 50 881.568 50C871.844 50 863.02 35.9927 865.389 25.5636C867.759 15.1345 875.93 2 884.101 2H912.699C913.884 2 915.355 2.69818 916.907 3.74546C922.177 7.19273 925.772 12.9527 927.202 19.3673C928.142 23.5564 929 28.5309 929 32.5455C929 41.2727 920.829 50 916.744 50C912.658 50 900.402 50 896.316 41.2727V41.2727Z",
        x: 886.081,
        y: 31.3182,
    },
    {
        toothNum: "15",
        pathId: "Vector_15",
        d: "M981.619 41.2504C981.619 41.2504 977.079 50 967.186 50C957.293 50 949.185 41.2504 949.185 36.8755C949.185 34.3819 947.684 19.2013 953.239 10.6266C957.496 4.06442 967.024 3.75818 977.565 1.87701C988.106 -0.00416477 996.783 1.00204 1001.89 6.25182C1008.13 12.6828 1010 23.7511 1010 23.7511C1010 23.7511 1010 41.2504 993.783 45.6252C993.783 45.6252 985.674 45.6252 981.619 41.2504V41.2504Z",
        x: 969.96,
        y: 31.3182,
    },
    {
        toothNum: "16",
        pathId: "Vector_16",
        d: "M1059.08 1C1059.08 1 1030 1 1030 22.5C1030 44 1042.46 44 1042.46 44C1042.46 44 1054.92 44 1054.92 35.4C1054.92 35.4 1054.92 44 1067.38 44C1079.85 44 1084 26.8 1084 22.5C1084 18.2 1079.85 1 1059.08 1V1Z",
        x: 1047.32,
        y: 28.3182,
    },
];

const svgTooth_mandible = [
    {
        toothNum: "48",
        pathId: "Vector_16",
        d: "M38.4114 47.9638C38.4114 47.9638 48.9241 49.2637 52.892 47.9638C56.8598 46.6639 65 37.088 65 28.8987C65 20.7093 60.9094 12 52.7283 12C46.9607 12 41.193 14.1665 38.2887 15.4231C37.3069 15.8564 36.2025 15.5097 35.4253 14.6864C34.239 13.4732 31.662 12 25.8534 12C19.3903 12 17.1405 15.683 16.3224 18.2395C15.9542 19.4527 14.8089 20.1027 13.6635 19.7127C10.9229 18.7595 6.38234 17.7629 3.64167 20.666C0.737372 23.7424 -0.448889 33.665 3.64167 42.3309C7.73222 50.9969 11.8228 50.9969 15.9133 50.9969C20.0039 50.9969 28.185 51.1702 32.2756 48.9171C36.3661 46.6639 38.4114 47.9638 38.4114 47.9638Z",
        x: 23.354,
        y: 37.3182,
    },
    {
        toothNum: "47",
        pathId: "Vector_15",
        d: "M114.61 49.6802C115.098 49.4231 115.708 49.2945 116.236 49.4659C119.123 50.2372 121.399 52.894 130.709 52.894C137.782 52.894 147.946 38.796 146.929 30.8257C145.913 22.8554 145.913 10 133.717 10C125.912 10 121.399 13.5138 119.204 16.042C118.269 17.1133 116.643 17.1133 115.708 16.042C113.513 13.5138 109.041 10 101.195 10C92.17 10 87.5762 19.1273 85.8281 23.7981C85.1777 25.5121 84.9338 27.269 85.0151 29.1116C85.381 35.7107 87.2916 52.8512 97.1296 52.8512C108.96 52.8512 105.667 54.051 114.61 49.6373V49.6802Z",
        x: 106.182,
        y: 37.3182,
    },
    {
        toothNum: "46",
        pathId: "Vector_14",
        d: "M206.342 50.6967L208.728 51.9447C212.882 54.0963 217.694 54.3115 221.971 52.461C225.59 50.8688 229.292 49.0184 229.868 48.0717C232.953 43.338 238.094 33.0531 235.091 20.5735C230.978 3.36021 221.149 8.82543 221.149 8.82543C221.149 8.82543 215.432 10.3316 212.1 12.6554C210.044 14.0755 207.453 14.2906 205.355 12.9136C202.106 10.7189 197.541 8.17993 193.345 8.0078C189.15 7.83566 185.778 10.5467 183.845 12.9136C182.364 14.721 180.02 15.4095 177.84 14.8501C175.125 14.1615 171.588 13.8603 169.285 16.2702C165.172 20.5735 165.172 55 181.624 55C193.016 55 197.705 53.0205 199.474 51.7725C200.42 51.127 201.489 50.8258 202.599 50.7828L206.301 50.6967H206.342Z",
        x: 191.362,
        y: 37.3182,
    },
    {
        toothNum: "45",
        pathId: "Vector_13",
        d: "M276.108 4C276.108 4 292.195 12.5888 296.217 21.1776C300.239 29.7664 287.852 60.2136 279.969 59.9989L272.087 59.8271C272.087 59.8271 256 51.2383 256 29.7664C256 8.29439 276.108 4 276.108 4Z",
        x: 264.944,
        y: 37.3182,
    },
    {
        toothNum: "44",
        pathId: "Vector_12",
        d: "M337.17 6C337.17 6 353.306 14.5144 353.992 27.3712C354.234 31.6284 349.272 58.8319 337.17 57.9804C325.068 57.129 317 32.3947 317 27.3286C317 23.0714 321.034 10.2998 337.17 6.04257V6Z",
        x: 325.15,
        y: 37.3182,
    },
    {
        toothNum: "43",
        pathId: "Vector_11",
        d: "M374.118 22.3424C374.118 22.3424 390.579 1 397.727 1C404.875 1 416.475 14.3496 416.802 18.3035C417.129 22.2574 419.334 65.3249 395.44 62.9015C371.545 60.4782 374.077 22.3424 374.077 22.3424H374.118Z",
        x: 385.235,
        y: 37.3182,
    },
    {
        toothNum: "42",
        pathId: "Vector_10",
        d: "M438.34 7.84315C439.188 6.10331 440.885 5 442.744 5H463.959C463.959 5 468 5 468 9.24351C468 13.487 468 57.7892 453.857 58.9774C439.714 60.1656 435.349 14.1235 437.531 9.58299L438.34 7.88559V7.84315Z",
        x: 443.085,
        y: 37.3182,
    },
    {
        toothNum: "41",
        pathId: "Vector_9",
        d: "M491.926 6H511.692C513.299 6 514.786 6.8075 515.71 8.21C516.795 9.825 518 12.1625 518 14.5C518 18.75 513.982 57 501.93 57C489.877 57 486.864 14.5 488.35 10.25C489.837 6 489.877 6 491.886 6H491.926Z",
        x: 494.282,
        y: 37.3182,
    },
    {
        toothNum: "31",
        pathId: "Vector_8",
        d: "M564.074 6H544.308C542.701 6 541.214 6.8075 540.29 8.21C539.205 9.825 538 12.1625 538 14.5C538 18.75 542.018 57 554.07 57C566.123 57 569.136 14.5 567.65 10.25C566.163 6 566.123 6 564.114 6H564.074Z",
        x: 543.054,
        y: 37.3182,
    },
    {
        toothNum: "32",
        pathId: "Vector_7",
        d: "M617.66 7.84315C616.812 6.10331 615.115 5 613.256 5H592.041C592.041 5 588 5 588 9.24351C588 13.487 588 57.7892 602.143 58.9774C616.287 60.1656 620.651 14.1235 618.469 9.58299L617.66 7.88559V7.84315Z",
        x: 593.114,
        y: 37.3182,
    },
    {
        toothNum: "33",
        pathId: "Vector_6",
        d: "M681.882 22.3424C681.882 22.3424 665.421 1 658.273 1C651.125 1 639.525 14.3496 639.198 18.3035C638.871 22.2574 636.666 65.3249 660.56 62.9015C684.455 60.4782 681.923 22.3424 681.923 22.3424H681.882Z",
        x: 650.275,
        y: 37.3182,
    },
    {
        toothNum: "34",
        pathId: "Vector_5",
        d: "M718.83 6C718.83 6 702.694 14.5144 702.008 27.3712C701.766 31.6284 706.728 58.8319 718.83 57.9804C730.932 57.129 739 32.3947 739 27.3286C739 23.0714 734.966 10.2998 718.83 6.04257V6Z",
        x: 711.178,
        y: 37.3182,
    },
    {
        toothNum: "35",
        pathId: "Vector_4",
        d: "M780.401 4C780.401 4 763.922 12.5888 759.802 21.1776C755.682 29.7664 768.371 60.2136 776.446 59.9989L784.521 59.8271C784.521 59.8271 801 51.2383 801 29.7664C801 8.29439 780.401 4 780.401 4Z",
        x: 768.99,
        y: 37.3182,
    },
    {
        toothNum: "36",
        pathId: "Vector_3",
        d: "M850.658 50.6967L848.272 51.9447C844.118 54.0963 839.306 54.3115 835.029 52.461C831.41 50.8688 827.708 49.0184 827.132 48.0717C824.047 43.338 818.906 33.0531 821.909 20.5735C826.022 3.36021 835.851 8.82543 835.851 8.82543C835.851 8.82543 841.568 10.3316 844.9 12.6554C846.956 14.0755 849.547 14.2906 851.645 12.9136C854.894 10.7189 859.459 8.17993 863.655 8.0078C867.85 7.83566 871.222 10.5467 873.155 12.9136C874.636 14.721 876.98 15.4095 879.16 14.8501C881.875 14.1615 885.412 13.8603 887.715 16.2702C891.828 20.5735 891.828 55 875.376 55C863.984 55 859.295 53.0205 857.526 51.7725C856.58 51.127 855.511 50.8258 854.401 50.7828L850.699 50.6967H850.658Z",
        x: 845.401,
        y: 37.3182,
    },
    {
        toothNum: "37",
        pathId: "Vector_2",
        d: "M942.39 49.6802C941.902 49.4231 941.292 49.2945 940.764 49.4659C937.877 50.2372 935.601 52.894 926.291 52.894C919.218 52.894 909.054 38.796 910.071 30.8257C911.087 22.8554 911.087 10 923.283 10C931.088 10 935.601 13.5138 937.796 16.042C938.731 17.1133 940.357 17.1133 941.292 16.042C943.487 13.5138 947.959 10 955.805 10C964.83 10 969.424 19.1273 971.172 23.7981C971.822 25.5121 972.066 27.269 971.985 29.1116C971.619 35.7107 969.708 52.8512 959.87 52.8512C948.04 52.8512 951.333 54.051 942.39 49.6373V49.6802Z",
        x: 930.226,
        y: 37.3182,
    },
    {
        toothNum: "38",
        pathId: "Vector",
        d: "M1019 47.9638C1019 47.9638 1008.33 49.2637 1004.3 47.9638C1000.27 46.6639 992 37.088 992 28.8987C992 20.7093 996.154 12 1004.46 12C1010.32 12 1016.18 14.1665 1019.13 15.4231C1020.13 15.8564 1021.25 15.5097 1022.04 14.6864C1023.24 13.4732 1025.86 12 1031.76 12C1038.32 12 1040.61 15.683 1041.44 18.2395C1041.81 19.4527 1042.98 20.1027 1044.14 19.7127C1046.92 18.7595 1051.53 17.7629 1054.32 20.666C1057.27 23.7424 1058.47 33.665 1054.32 42.3309C1050.16 50.9969 1046.01 50.9969 1041.85 50.9969C1037.7 50.9969 1029.39 51.1702 1025.24 48.9171C1021.08 46.6639 1019 47.9638 1019 47.9638Z",
        x: 1014.41,
        y: 37.3182,
    },
];

const svgTooth_mandible_us = [
    {
        toothNum: "32",
        pathId: "Vector_16",
        d: "M38.4114 47.9638C38.4114 47.9638 48.9241 49.2637 52.892 47.9638C56.8598 46.6639 65 37.088 65 28.8987C65 20.7093 60.9094 12 52.7283 12C46.9607 12 41.193 14.1665 38.2887 15.4231C37.3069 15.8564 36.2025 15.5097 35.4253 14.6864C34.239 13.4732 31.662 12 25.8534 12C19.3903 12 17.1405 15.683 16.3224 18.2395C15.9542 19.4527 14.8089 20.1027 13.6635 19.7127C10.9229 18.7595 6.38234 17.7629 3.64167 20.666C0.737372 23.7424 -0.448889 33.665 3.64167 42.3309C7.73222 50.9969 11.8228 50.9969 15.9133 50.9969C20.0039 50.9969 28.185 51.1702 32.2756 48.9171C36.3661 46.6639 38.4114 47.9638 38.4114 47.9638Z",
        x: 23.354,
        y: 37.3182,
    },
    {
        toothNum: "31",
        pathId: "Vector_15",
        d: "M114.61 49.6802C115.098 49.4231 115.708 49.2945 116.236 49.4659C119.123 50.2372 121.399 52.894 130.709 52.894C137.782 52.894 147.946 38.796 146.929 30.8257C145.913 22.8554 145.913 10 133.717 10C125.912 10 121.399 13.5138 119.204 16.042C118.269 17.1133 116.643 17.1133 115.708 16.042C113.513 13.5138 109.041 10 101.195 10C92.17 10 87.5762 19.1273 85.8281 23.7981C85.1777 25.5121 84.9338 27.269 85.0151 29.1116C85.381 35.7107 87.2916 52.8512 97.1296 52.8512C108.96 52.8512 105.667 54.051 114.61 49.6373V49.6802Z",
        x: 106.182,
        y: 37.3182,
    },
    {
        toothNum: "30",
        pathId: "Vector_14",
        d: "M206.342 50.6967L208.728 51.9447C212.882 54.0963 217.694 54.3115 221.971 52.461C225.59 50.8688 229.292 49.0184 229.868 48.0717C232.953 43.338 238.094 33.0531 235.091 20.5735C230.978 3.36021 221.149 8.82543 221.149 8.82543C221.149 8.82543 215.432 10.3316 212.1 12.6554C210.044 14.0755 207.453 14.2906 205.355 12.9136C202.106 10.7189 197.541 8.17993 193.345 8.0078C189.15 7.83566 185.778 10.5467 183.845 12.9136C182.364 14.721 180.02 15.4095 177.84 14.8501C175.125 14.1615 171.588 13.8603 169.285 16.2702C165.172 20.5735 165.172 55 181.624 55C193.016 55 197.705 53.0205 199.474 51.7725C200.42 51.127 201.489 50.8258 202.599 50.7828L206.301 50.6967H206.342Z",
        x: 191.362,
        y: 37.3182,
    },
    {
        toothNum: "29",
        pathId: "Vector_13",
        d: "M276.108 4C276.108 4 292.195 12.5888 296.217 21.1776C300.239 29.7664 287.852 60.2136 279.969 59.9989L272.087 59.8271C272.087 59.8271 256 51.2383 256 29.7664C256 8.29439 276.108 4 276.108 4Z",
        x: 264.944,
        y: 37.3182,
    },
    {
        toothNum: "28",
        pathId: "Vector_12",
        d: "M337.17 6C337.17 6 353.306 14.5144 353.992 27.3712C354.234 31.6284 349.272 58.8319 337.17 57.9804C325.068 57.129 317 32.3947 317 27.3286C317 23.0714 321.034 10.2998 337.17 6.04257V6Z",
        x: 325.15,
        y: 37.3182,
    },
    {
        toothNum: "27",
        pathId: "Vector_11",
        d: "M374.118 22.3424C374.118 22.3424 390.579 1 397.727 1C404.875 1 416.475 14.3496 416.802 18.3035C417.129 22.2574 419.334 65.3249 395.44 62.9015C371.545 60.4782 374.077 22.3424 374.077 22.3424H374.118Z",
        x: 385.235,
        y: 37.3182,
    },
    {
        toothNum: "26",
        pathId: "Vector_10",
        d: "M438.34 7.84315C439.188 6.10331 440.885 5 442.744 5H463.959C463.959 5 468 5 468 9.24351C468 13.487 468 57.7892 453.857 58.9774C439.714 60.1656 435.349 14.1235 437.531 9.58299L438.34 7.88559V7.84315Z",
        x: 443.085,
        y: 37.3182,
    },
    {
        toothNum: "25",
        pathId: "Vector_9",
        d: "M491.926 6H511.692C513.299 6 514.786 6.8075 515.71 8.21C516.795 9.825 518 12.1625 518 14.5C518 18.75 513.982 57 501.93 57C489.877 57 486.864 14.5 488.35 10.25C489.837 6 489.877 6 491.886 6H491.926Z",
        x: 494.282,
        y: 37.3182,
    },
    {
        toothNum: "24",
        pathId: "Vector_8",
        d: "M564.074 6H544.308C542.701 6 541.214 6.8075 540.29 8.21C539.205 9.825 538 12.1625 538 14.5C538 18.75 542.018 57 554.07 57C566.123 57 569.136 14.5 567.65 10.25C566.163 6 566.123 6 564.114 6H564.074Z",
        x: 543.054,
        y: 37.3182,
    },
    {
        toothNum: "23",
        pathId: "Vector_7",
        d: "M617.66 7.84315C616.812 6.10331 615.115 5 613.256 5H592.041C592.041 5 588 5 588 9.24351C588 13.487 588 57.7892 602.143 58.9774C616.287 60.1656 620.651 14.1235 618.469 9.58299L617.66 7.88559V7.84315Z",
        x: 593.114,
        y: 37.3182,
    },
    {
        toothNum: "22",
        pathId: "Vector_6",
        d: "M681.882 22.3424C681.882 22.3424 665.421 1 658.273 1C651.125 1 639.525 14.3496 639.198 18.3035C638.871 22.2574 636.666 65.3249 660.56 62.9015C684.455 60.4782 681.923 22.3424 681.923 22.3424H681.882Z",
        x: 650.275,
        y: 37.3182,
    },
    {
        toothNum: "21",
        pathId: "Vector_5",
        d: "M718.83 6C718.83 6 702.694 14.5144 702.008 27.3712C701.766 31.6284 706.728 58.8319 718.83 57.9804C730.932 57.129 739 32.3947 739 27.3286C739 23.0714 734.966 10.2998 718.83 6.04257V6Z",
        x: 711.178,
        y: 37.3182,
    },
    {
        toothNum: "20",
        pathId: "Vector_4",
        d: "M780.401 4C780.401 4 763.922 12.5888 759.802 21.1776C755.682 29.7664 768.371 60.2136 776.446 59.9989L784.521 59.8271C784.521 59.8271 801 51.2383 801 29.7664C801 8.29439 780.401 4 780.401 4Z",
        x: 768.99,
        y: 37.3182,
    },
    {
        toothNum: "19",
        pathId: "Vector_3",
        d: "M850.658 50.6967L848.272 51.9447C844.118 54.0963 839.306 54.3115 835.029 52.461C831.41 50.8688 827.708 49.0184 827.132 48.0717C824.047 43.338 818.906 33.0531 821.909 20.5735C826.022 3.36021 835.851 8.82543 835.851 8.82543C835.851 8.82543 841.568 10.3316 844.9 12.6554C846.956 14.0755 849.547 14.2906 851.645 12.9136C854.894 10.7189 859.459 8.17993 863.655 8.0078C867.85 7.83566 871.222 10.5467 873.155 12.9136C874.636 14.721 876.98 15.4095 879.16 14.8501C881.875 14.1615 885.412 13.8603 887.715 16.2702C891.828 20.5735 891.828 55 875.376 55C863.984 55 859.295 53.0205 857.526 51.7725C856.58 51.127 855.511 50.8258 854.401 50.7828L850.699 50.6967H850.658Z",
        x: 845.401,
        y: 37.3182,
    },
    {
        toothNum: "18",
        pathId: "Vector_2",
        d: "M942.39 49.6802C941.902 49.4231 941.292 49.2945 940.764 49.4659C937.877 50.2372 935.601 52.894 926.291 52.894C919.218 52.894 909.054 38.796 910.071 30.8257C911.087 22.8554 911.087 10 923.283 10C931.088 10 935.601 13.5138 937.796 16.042C938.731 17.1133 940.357 17.1133 941.292 16.042C943.487 13.5138 947.959 10 955.805 10C964.83 10 969.424 19.1273 971.172 23.7981C971.822 25.5121 972.066 27.269 971.985 29.1116C971.619 35.7107 969.708 52.8512 959.87 52.8512C948.04 52.8512 951.333 54.051 942.39 49.6373V49.6802Z",
        x: 930.226,
        y: 37.3182,
    },
    {
        toothNum: "17",
        pathId: "Vector",
        d: "M1019 47.9638C1019 47.9638 1008.33 49.2637 1004.3 47.9638C1000.27 46.6639 992 37.088 992 28.8987C992 20.7093 996.154 12 1004.46 12C1010.32 12 1016.18 14.1665 1019.13 15.4231C1020.13 15.8564 1021.25 15.5097 1022.04 14.6864C1023.24 13.4732 1025.86 12 1031.76 12C1038.32 12 1040.61 15.683 1041.44 18.2395C1041.81 19.4527 1042.98 20.1027 1044.14 19.7127C1046.92 18.7595 1051.53 17.7629 1054.32 20.666C1057.27 23.7424 1058.47 33.665 1054.32 42.3309C1050.16 50.9969 1046.01 50.9969 1041.85 50.9969C1037.7 50.9969 1029.39 51.1702 1025.24 48.9171C1021.08 46.6639 1019 47.9638 1019 47.9638Z",
        x: 1014.41,
        y: 37.3182,
    },
];

const bridgeMaxilla = [
    { group: "18_17", left: "60px" },
    { group: "17_16", left: "140px" },
    { group: "16_15", left: "224px" },
    { group: "15_14", left: "286px" },
    { group: "14_13", left: "346px" },
    { group: "13_12", left: "410px" },
    { group: "12_11", left: "468px" },
    { group: "11_21", left: "538px" },
    { group: "21_22", left: "610px" },
    { group: "22_23", left: "664px" },
    { group: "23_24", left: "728px" },
    { group: "24_25", left: "792px" },
    { group: "25_26", left: "854px" },
    { group: "26_27", left: "936px" },
    { group: "27_28", left: "1016px" },
];

const bridgeMaxilla_us = [
    { group: "1_2", left: "60px" },
    { group: "2_3", left: "140px" },
    { group: "3_4", left: "224px" },
    { group: "4_5", left: "286px" },
    { group: "5_6", left: "346px" },
    { group: "6_7", left: "410px" },
    { group: "7_8", left: "468px" },
    { group: "8_9", left: "538px" },
    { group: "9_10", left: "610px" },
    { group: "10_11", left: "664px" },
    { group: "11_12", left: "728px" },
    { group: "12_13", left: "792px" },
    { group: "13_14", left: "854px" },
    { group: "14_15", left: "936px" },
    { group: "15_16", left: "1016px" },
];

const bridgeMandible = [
    { group: "48_47", left: "84px" },
    { group: "47_46", left: "166px" },
    { group: "46_45", left: "256px" },
    { group: "45_44", left: "316px" },
    { group: "44_43", left: "372px" },
    { group: "43_42", left: "436px" },
    { group: "42_41", left: "488px" },
    { group: "41_31", left: "538px" },
    { group: "31_32", left: "588px" },
    { group: "32_33", left: "638px" },
    { group: "33_34", left: "702px" },
    { group: "34_35", left: "758px" },
    { group: "35_36", left: "820px" },
    { group: "36_37", left: "910px" },
    { group: "37_38", left: "994px" },
];

const bridgeMandible_us = [
    { group: "32_31", left: "84px" },
    { group: "31_30", left: "166px" },
    { group: "30_29", left: "256px" },
    { group: "29_28", left: "316px" },
    { group: "28_27", left: "372px" },
    { group: "27_26", left: "436px" },
    { group: "26_25", left: "488px" },
    { group: "25_24", left: "538px" },
    { group: "24_23", left: "588px" },
    { group: "23_22", left: "638px" },
    { group: "22_21", left: "702px" },
    { group: "21_20", left: "758px" },
    { group: "20_19", left: "820px" },
    { group: "19_18", left: "910px" },
    { group: "18_17", left: "994px" },
];

export const ACCOUNT_STATUS = () => {
    const t = useTranslate();
    const paymentState = [
        {
            status: "M",
            text: t("payment_billWait"),
        },
        {
            status: "I",
            text: t("payment_billWaiting"),
        },
        {
            status: "C",
            text: t("payment_payComplite"),
        },
    ];
    return paymentState;
};

export const TRANSACTION_TYPE = () => {
    const t = useTranslate();
    const transactionType = [
        {
            type: "I",
            text: t("payment_deposit"),
        },
        {
            type: "O",
            text: t("payment_withdraw"),
        },
        {
            type: "A",
            text: t("payment_adjust"),
        },
    ];
    return transactionType;
};